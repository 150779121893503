import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";

import createSlug from "../../tools/slug";

const HomeProject = ({
  project: { featurePhoto, title, subTitle, description },
  reverse,
}) => {
  const featImage = getImage(featurePhoto.asset);
  return (
    <>
      <div className={`md:p-20 ${reverse ? "col-start-2" : ""}`}>
        <GatsbyImage image={featImage} />
      </div>
      <section
        className={`p-20 flex flex-col justify-center ${
          reverse ? "col-start-1" : ""
        }`}
      >
        <h2 className="text-3xl leading-6 font-medium text-gray-50">{title}</h2>
        <p className="mt-2 text-base text-gray-400">{subTitle}</p>
        <p className="mt-4 text-base text-gray-50">{description}</p>
        <Link
          className="mt-4 underline text-base text-gray-50"
          to={`/projects/${createSlug(title)}`}
        >
          See more of {title}
        </Link>
      </section>
    </>
  );
};

export default HomeProject;

export const query = graphql`
  fragment IndexProjectFragment on SanityProject {
    title
    subTitle
    description
    design
    architect
    builder
    photo
    featurePhoto {
      asset {
        gatsbyImageData(width: 1300, placeholder: BLURRED)
      }
    }
  }
`;
