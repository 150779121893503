import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import HomeProject from "../components/homeproject";
import Seo from "../components/seo";
import Testimonial from "../components/testimonial";

const logoStyle = {
  marginTop: -75,
  marginLeft: -75,
};

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      sanityHomePage {
        id
        services
        image1 {
          asset {
            gatsbyImageData(width: 1300, placeholder: BLURRED)
          }
        }
        image2 {
          asset {
            gatsbyImageData(width: 1300, placeholder: BLURRED)
          }
        }
        testimonial1 {
          ...TestimonialFragment
        }
        testimonial2 {
          ...TestimonialFragment
        }
        project1 {
          ...IndexProjectFragment
        }
        project2 {
          ...IndexProjectFragment
        }
        project3 {
          ...IndexProjectFragment
        }
      }
    }
  `);
  const image1 = getImage(data.sanityHomePage.image1.asset);
  const image2 = getImage(data.sanityHomePage.image2.asset);
  return (
    <Layout>
      <Seo
        title="SCR Landscapes"
        description="SCR Landscapes has worked with the best architects, builders and landscape designers in Victoria to create beautiful, bespoke outdoor spaces."
      />
      <div className="md:grid md:grid-cols-2 md:grid-flow-row-dense">
        <GatsbyImage className="h-screen" image={image1} />
        <GatsbyImage className="h-screen hidden md:block" image={image2} />
        <div
          style={logoStyle}
          className="absolute top-1/2 left-1/2 bg-opacity-0 bg-gray-500 rounded-full"
        >
          <StaticImage
            src="../images/wlogo.png"
            alt="Company Logo"
            layout="fixed"
            width={150}
          />
        </div>
        <p className="md:col-span-2 my-48 p-20 text-gray-50 text-3xl text-center">
          {data.sanityHomePage.services}
        </p>
        <HomeProject project={data.sanityHomePage.project1} />
        <Testimonial testimonial={data.sanityHomePage.testimonial1} />
        <HomeProject reverse={true} project={data.sanityHomePage.project2} />
        <Testimonial testimonial={data.sanityHomePage.testimonial2} />
        <HomeProject project={data.sanityHomePage.project3} />
      </div>
    </Layout>
  );
};

export default IndexPage;
